import React from "react"
import { graphql } from "gatsby"
import { Parser } from "html-to-react"

import Container from "react-bootstrap/Container"

import Layout from "../components/layout"
import Hero from "../components/Hero"
import { dateFormat } from "../components/helpers"
import ThoughtBanner from "../components/ThoughtBanner"
import SEO from "../components/seo"

const BlogPost = ({ data, location }) => {
  const post = data.allButterPost.edges[0].node

  return (
    <Layout hero={<Hero />}>
      <SEO title={post.title} path={location.pathname} />
      <ThoughtBanner data={post} as="section" />
      <Container as="section" className="thought-section pb-5">
        <p className="h1">{post.title}</p>
        <p className="lead mb-5">
          {post.author.first_name} {post.author.last_name} |{" "}
          {dateFormat(post.published)}
        </p>
        {new Parser().parse(post.body)}
      </Container>
      <hr className="mb-5 w-25 pb-5" />
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    allButterPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          slug
          featured_image
          title
          summary
          published
          body
          author {
            first_name
            last_name
          }
        }
      }
    }
  }
`
