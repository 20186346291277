import React from 'react'

const ThoughtBanner = ({ data, as }) => {
    const CardElement = as ? as : 'article'


    return (
        <CardElement className="mobile thought__banner" style={{ backgroundImage: `url(${data.featured_image})`}}>
        </CardElement>
    )
}

export default ThoughtBanner